import React from 'react'
import { Link } from 'react-router-dom';
import footerLogo from '../assets/expertlancing.png';
import './Footer.css';

function Footer() {
    return (
        <section class='footer-sec flex flex-col p-4'>
            <div class=' flex flex-row gap-16 text-white w-full h-fit xs:max-sm:flex-col xs:max-sm:gap-2'>

                <div class='w-2/6 flex flex-col p-6 justify-center gap-6 xs:max-sm:gap-3 xs:max-sm:w-full'>
                    <img src={footerLogo} alt='footer' class='w-6/12' />
                    <p class='sub-text'>
                        At Expertlancing, we view our clients not just as partners but as a integral members of our team.
                    </p>
                    <div class='flex flex-row gap-5 xs:max-sm:gap-2'>
                        <Link to='/aboutus' className="bg-white hover:bg-gray-100 text-gray-800 text-sm font-semibold py-1 px-4 border border-gray-400 rounded shadow xs:max-sm:text-[10px]">
                            Explore
                        </Link>
                        <Link to='/insights' className="bg-white hover:bg-gray-100 text-gray-800 text-sm font-semibold py-1 px-4 border border-gray-400 rounded shadow xs:max-sm:text-[10px]">
                            Insights 
                        </Link>
                    </div>
                </div>
                <div class='w-1/4 flex flex-col justify-center gap-2 p-6 xs:max-sm:w-full'>
                    <span class='text-xl font-bold'>Get In Touch</span>
                    <Link to={'/aboutus'} class='text-sm cursor-pointer no-underline'>Know us better</Link>
                    <Link to={'/ourpeople'} class='text-sm cursor-pointer no-underline'>Our Leadership</Link>
                    <Link to={'/career'} class='text-sm cursor-pointer no-underline'>Looking for a career</Link>
                    <Link to={'/contact'} class='text-sm cursor-pointer no-underline'>Let's connect</Link>
                </div>
                <div class='w-2/6 flex flex-col justify-center gap-2 p-6 xs:max-sm:w-full'>
                    <div class='flex flex-row gap-3'>
                        <span><i class="fa-solid fa-phone"></i></span>
                        <span class='text-sm'> +1 (973).577.6371 (US)
                        </span>
                    </div>
                    <div class='flex flex-row gap-3'>
                        <span><i class="fa-solid fa-tty"></i></span>
                        <span class='text-sm'> +91 124 420 9029 (IN)
                        </span>
                    </div>
                    <div class='flex flex-row gap-3'>
                        <span class='text-sm'><i class="fa-solid fa-envelope"></i></span>
                        <span> info@expertlancing.com</span>
                    </div>
                    <div class='flex flex-row gap-3'>
                        <span><i class="fa-solid fa-location-dot"></i></span>
                        <span class='text-sm'>
                            1004 , 10th Floor, Iris Tech Park,
                            Sohna Road, Gurugram
                        </span>
                    </div>
                </div>
            </div>


            <div class='flex flex-row text-white justify-between px-8'>
                <span class='text-white text-xs'>@ Copyright Expertlancing Research Services</span>
                <div class='flex flex-row gap-5'>
                <a target='blank' href='https://www.linkedin.com/company/expertlancing-ip-services/'>
                    <i class="fa-brands fa-linkedin fa-2x"></i>
                </a>
                <a target='blank' href='https://www.youtube.com/@expertlancingresearchservi880'>
                    <i class="fa-brands fa-youtube fa-2x"></i>
                </a>
                </div>
            </div>
        </section>
    )
}

export default Footer