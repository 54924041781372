import React, { useState, useEffect } from "react";
import './subInsight.css';
import News from "../components/newsletter";
import { format } from 'date-fns';

const InsightSubpage = ({ data }) => {
    const [insights_ccc, setinsights_ccc] = useState({});
    const [images, setImages] = useState([]);
    const insightsData = data;
    document.title = `${insightsData.title}`;

    useEffect(() => {
        const processedData = {};
        const imageMap = {};
        for (let i = 1; i <= insightsData.NoofParagraphs; i++) {
            let headData = '', paraData = '';
            if (insightsData[`head_${i}`]) {
                headData = insightsData[`head_${i}`];
            }
            if (insightsData[`Para_${i}`]) {
                paraData = insightsData[`Para_${i}`];
            }
            if (insightsData[`image_${i}`]) {
                imageMap[insightsData[`image_${i}_loc`]] = insightsData[`image_${i}`];
            }
            processedData[i] = [headData, paraData];
        }
        setinsights_ccc(processedData);
        setImages(imageMap);
    }, [insightsData]);

    // Add images to insights_ccc
    for (let [key, value] of Object.entries(images)) {
        const index = key.slice(0, -1);
        if (insights_ccc[index]) {
            insights_ccc[index].push(value);
            insights_ccc[index].push(key.slice(-1));
        }
    }

    const sub_data = [];
    for (let [key, value] of Object.entries(insights_ccc)) {
        const iconUrl = `/insights_img/${value[2]}`;
        const imgClass = `my-4 head-img-${value[3]}`;
        sub_data.push((
            <div key={key} className="hyperlink w-9/12 my-4">
                <h3 className="text-2xl font-bold" dangerouslySetInnerHTML={{ __html: value[0] }}></h3>
                <div>
                    {value[2] && (
                        <img src={iconUrl} alt="" className={imgClass} />
                    )}
                    <p className="text-[14px] py-2 text-justify whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: value[1] }}></p>
                </div>
                {/* <br/> */}
            </div>
        ));
    }

    function ExcelDateToJSDate(serial) {
        const utc_days = Math.floor(serial - 25569);
        const utc_value = utc_days * 86400;
        const date_info = new Date(utc_value * 1000);

        const fractional_day = serial - Math.floor(serial) + 0.0000001;
        let total_seconds = Math.floor(86400 * fractional_day);
        const seconds = total_seconds % 60;
        total_seconds -= seconds;
        const hours = Math.floor(total_seconds / (60 * 60));
        const minutes = Math.floor(total_seconds / 60) % 60;

        return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    }

    // Convert and format the date
    let articleDate = ExcelDateToJSDate(insightsData.Submitted_on);
    let formattedDate = format(new Date(articleDate.toLocaleDateString()), 'MMMM dd, yyyy');; // Format the date to a readable format
    //console.log(formattedDate)

    const imagePath = `/insights_img/${insightsData.main_image_all}`;
    return (
        <section className="flex flex-col items-center">
            <div className='h-80 w-9/12 py-10'>
                <img className='brightness-50 h-full w-full object-cover' src={imagePath} alt="Insight Banner" />
            </div>
            <h2 className="text-3xl font-semibold w-9/12">{insightsData.title}</h2>
            <p className="w-9/12 flex items-center text-xs opacity-70 py-5 px-5">
                <i className="fa fa-circle text-xxs text-red-800 pr-2"></i>{ insightsData.insight_type }&nbsp;&nbsp;&nbsp;
                <i className="fa fa-circle text-xxs text-red-800 pr-2"></i><span>{ formattedDate }</span>&nbsp;&nbsp;&nbsp;
            </p>
            <p className="w-9/12 flex items-center text-sm py-5 ">Author :&nbsp; <span className="text-black text-[14px]"> { insightsData.Prepared_By }</span>&nbsp;&nbsp;&nbsp;
            </p>
            {/* Dynamically render the content */}
            {sub_data}
            <News/>
        </section>
    );
}

export default InsightSubpage;
