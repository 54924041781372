import React from 'react';
import { createBrowserRouter, ScrollRestoration } from 'react-router-dom';
import Navbar from './components/Nav';
import Footer from './components/Footer';
import Home from './pages/Homepage';
import SolutionsContent from './pages/SolutionsContent';
import Contact from './pages/Contact';
import Elabs from './pages/Elabs';
import Insights from './pages/Insights';
import IndustryCont from './pages/IndustryContent';
import SubIndustry from './pages/SubIndustry';
import Career from './pages/Career';
import About from './pages/About';
import InsightSubpage from './pages/Insight-subpage';
import Ourpeople from './pages/Ourpeople';
import ElabsVM from './pages/ElabsVM';
import Sustainability from './pages/Sustainability';

export const createRoutes = (industry, services, insights) => {
  const pathmaker = [
    { path: "/", element: <><ScrollRestoration /><Navbar /><Home data={insights} /><Footer /></> },
    // { path: "/industry", element: <><ScrollRestoration /><Navbar /><SubIndustry /><Footer /></> },
    { path: "/contact", element: <><ScrollRestoration /><Navbar /><Contact /><Footer /></> },
    { path: "/career", element: <><ScrollRestoration /><Navbar /><Career /><Footer /></> },
    { path: "/aboutus", element: <><ScrollRestoration /><Navbar /><About /><Footer /></> },
    { path: "/labs", element: <><ScrollRestoration /><Navbar /><Elabs /><Footer /></> },
    { path: "/elabs/visionmap", element: <><ScrollRestoration /><Navbar /><ElabsVM /><Footer /></> },
    { path: "/insights", element: <><ScrollRestoration /><Navbar /><Insights /><Footer /></> },
    { path: "/ourpeople", element: <><ScrollRestoration /><Navbar /><Ourpeople /><Footer /></> },
    { path: "/sustainability", element: <><ScrollRestoration /><Navbar /><Sustainability /><Footer /></> }
  ];

  industry.forEach(industryData => {
    Object.keys(industryData).forEach(key => {
      if (key.includes('Sub_category')) {
        const createData = {
          title: industryData[key],
          banner: industryData[key + '_banner'],
          tagline: industryData[key + '_tagline'],
          overview: industryData[key + '_overview'],
          infographic: industryData[key + '_infographic'],
          testimonial: industryData[key + '_testimonial'],
          testimonial_name: industryData[key + '_testimonial_name'],
          testimonial2: industryData[key + '_testimonial2'],
          testimonial_name2: industryData[key + '_testimonial_name2'],
          testimonial3: industryData[key + '_testimonial3'],
          testimonial_name3: industryData[key + '_testimonial_name3'],
        };

        pathmaker.push({
          path: "/" + industryData[key].replaceAll(' ', '-'),
          element: <><ScrollRestoration /><Navbar /><SubIndustry data={createData} /><Footer /></>
        });
      }
    });
  });

  services.forEach(service => {
    pathmaker.push({
      path: "/" + service.Name.replaceAll(' ', '-'),
      element: <><ScrollRestoration /><Navbar /><SolutionsContent data={service} /><Footer /></>
    });
  });

  Object.keys(insights).forEach(key => {
    pathmaker.push({
      path: "/" + insights[key].title.replaceAll(' ', '-'),
      element: <><ScrollRestoration /><Navbar /><InsightSubpage data={insights[key]} /><Footer /></>
    });
  });

  industry.forEach(industryData => {
    pathmaker.push({
      path: "/" + industryData.Name.replaceAll(' ', '-'),
      element: <><ScrollRestoration /><Navbar /><IndustryCont data={industryData} /><Footer /></>
    });
    // console.log(industryData.Name.replaceAll(' ', '-'))
  });

  return createBrowserRouter(pathmaker);
};
