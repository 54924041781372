import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import fetchAndReadExcel from '../components/fetchAndReadExcel';
import { format } from 'date-fns';

const Insights = () => {
    const [insights, setInsights] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isIndustriesVisible, setIndustriesVisible] = useState(false);
    const [isSolutionsVisible, setSolutionsVisible] = useState(false);
    const [selectedIndustries, setSelectedIndustries] = useState([]);
    const [isAnyChecked, setIsAnyChecked] = useState(false); // New state to track if any checkbox is checked
    const [filter, setFilter] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All');


    useEffect(() => {
        const getData = async () => {
            try {
                const jsonData = await fetchAndReadExcel();
                let data = jsonData['data3'];
                
                // Sort the data by date (assuming the date field is named 'date' and is in ISO format)
                data = data.sort((a, b) => new Date(b.Submitted_on) - new Date(a.Submitted_on));
                
                setInsights(data);
                setFilter(data); // Show all articles by default
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        getData();
    }, []);

    useEffect(() => {
        // Filter articles based on selected industries
        let filteredArticles = insights;
        if (selectedIndustries.length > 0) {
            filteredArticles = insights.filter(article =>
                selectedIndustries.some(industry =>
                    Object.keys(article).includes(industry)
                )
            );
        }

        // Further filter based on selected category
        if (selectedCategory !== 'All') {
            filteredArticles = filteredArticles.filter(article =>
                article.insight_type === selectedCategory
            );
        }

        setFilter(filteredArticles);
    }, [selectedIndustries, insights, selectedCategory]);

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setSelectedIndustries(prevState =>
            checked ? [...prevState, value] : prevState.filter(industry => industry !== value)
        );

        // Update isAnyChecked state based on the checkbox status
        setIsAnyChecked(checked || selectedIndustries.length > 0);
    };

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };

    const toggleIndustriesVisibility = () => {
        setIndustriesVisible(!isIndustriesVisible);
    };

    const toggleSolutionsVisibility = () => {
        setSolutionsVisible(!isSolutionsVisible);
    };

    if (loading) return <p className='opacity-0'>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    function ExcelDateToJSDate(serial) {
        const utc_days = Math.floor(serial - 25569);
        const utc_value = utc_days * 86400 * 1000; // Convert to milliseconds
        const date_info = new Date(utc_value);
    
        const fractional_day = serial - Math.floor(serial) + 0.0000001;
        let total_seconds = Math.floor(86400 * fractional_day);
        const seconds = total_seconds % 60;
        total_seconds -= seconds;
        const hours = Math.floor(total_seconds / (60 * 60));
        const minutes = Math.floor(total_seconds / 60) % 60;
    
        // Create a new date with valid values
        const year = date_info.getFullYear();
        const month = date_info.getMonth();
        const day = date_info.getDate();
    
        // Ensure the values are valid
        if (isNaN(year) || isNaN(month) || isNaN(day) || 
            isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
            return null; // or handle error as appropriate
        }
    
        return new Date(year, month, day, hours, minutes, seconds);
        
    }
    
    

    // function ExcelDateToJSDate(serial) {
    //     const utc_days = Math.floor(serial - 25569);
    //     const utc_value = utc_days * 86400;
    //     const date_info = new Date(utc_value * 1000);

    //     const fractional_day = serial - Math.floor(serial) + 0.0000001;
    //     let total_seconds = Math.floor(86400 * fractional_day);
    //     const seconds = total_seconds % 60;
    //     total_seconds -= seconds;
    //     const hours = Math.floor(total_seconds / (60 * 60));
    //     const minutes = Math.floor(total_seconds / 60) % 60;

    //     return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    // }

    return (
        <section>
            <Helmet>
            <title>Insights</title>
            <meta name='description' content='Discover our expertly crafted insights covering a wide range of dynamic industries.' />
            </Helmet>
            <div className="relative">
                <div className="absolute bottom-0 w-full flex items-center justify-center">
                    <div className="w-8/12 xs:max-sm:w-full">
                        <div className="flex flex-col gap-2 w-8/12 bg-black bg-opacity-55 text-white left-0 p-10 xs:max-sm:w-10/12">
                            <p><i className="fa fa-circle text-xs text-red-800 pr-2"></i>Article</p>
                            <h2>{insights[18]?.title}</h2>
                            <Link to={`/${insights[18].title.replaceAll(' ', '-')}`} className="flex items-center text-xs gap-2">Read More <i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-5 h-96 w-4/12 bg-black bg-opacity-55 xs:max-sm:hidden">
                        <p className="w-full text-white px-10"><i className="fa fa-circle text-xs text-red-800 pr-2"></i>Case Study</p>
                        <img className=" h-40 w-full object-cover px-10" src={`/insights_img/${insights[64].main_image_all}`} alt="" />
                        <div className="flex flex-col gap-2 w-12/12 text-white left-0 px-10">
                            <h2>{insights[64]?.title}</h2>
                            <Link to={`/${insights[64].title.replaceAll(' ', '-')}`} className="flex items-center text-xs gap-2">Read More <i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                        </div>
                    </div>
                </div>
                <img className="h-96 w-full object-cover" src={`/insights_img/${insights[18]?.main_image_all}`} alt="" />
            </div>
            <div className='sticky top-14 bg-white z-50 w-full shadow-sm xs:max-sm:hidden'>
                <ul className='flex items-center justify-center gap-28 w-full py-5'>
                    <li className={`cursor-pointer ${selectedCategory === 'All' ? 'text-red-800' : ''}`} onClick={() => handleCategoryChange('All')}>All</li>
                    <li className={`cursor-pointer ${selectedCategory === 'Article' ? 'text-red-800' : ''}`} onClick={() => handleCategoryChange('Article')}>Articles</li>
                    <li className={`cursor-pointer ${selectedCategory === 'Case Study' ? 'text-red-800' : ''}`} onClick={() => handleCategoryChange('Case Study')}>Case Studies</li>
                </ul>
            </div>
            <div className="flex items-start justify-start gap-10 m-10 xs:max-sm:flex-col xs:max-sm:m-5 ">
                <div className="w-3/12 h-full xs:max-sm:w-full xs:max-sm:hidden">
                    <div>
                        <h2 className="border border-white border-b-red-800 p-4 cursor-pointer" onClick={toggleIndustriesVisibility}>Industries <i className={`fa fa-caret-down text-xs transition-transform duration-300 ${isIndustriesVisible ? 'rotate-180' : ''}`} aria-hidden="true"></i></h2>
                        <ul className={`pt-5 transition-all duration-300 ${isIndustriesVisible ? 'block' : 'hidden'}`}>
                            {/* Checkbox items */}
                            <li className="flex items-center justify-start gap-5 pl-4"><input type="checkbox" id="Chemicals" value="Chemicals" onChange={handleCheckboxChange} /><label htmlFor="Chemicals">Chemicals</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input type="checkbox" id="Food and Nutrition" value="Food and Nutrition" onChange={handleCheckboxChange} /><label htmlFor="Food and Nutrition">Food and Nutrition</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input type="checkbox" id="Energy and Natural Resources" value="Energy and Natural Resources" onChange={handleCheckboxChange} /><label htmlFor="Energy and Natural Resources">Energy and Natural Resources</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input type="checkbox" id="Home and Personal Care" value="Home and Personal Care" onChange={handleCheckboxChange} /><label htmlFor="Home and Personal Care">Home and Personal Care</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input type="checkbox" id="Healthcare and Life Sciences" value="Healthcare and Life Sciences" onChange={handleCheckboxChange} /><label htmlFor="Healthcare and Life Sciences">Healthcare and Life Sciences</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input type="checkbox" id="Mobility" value="Mobility" onChange={handleCheckboxChange} /><label htmlFor="Mobility">Mobility</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input type="checkbox" id="Industrials" value="Industrials" onChange={handleCheckboxChange} /><label htmlFor="Industrials">Industrials</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input type="checkbox" id="Technology Media and Telecom" value="Technology Media and Telecom" onChange={handleCheckboxChange} /><label htmlFor="Technology Media and Telecom">Technology Media and Telecom</label></li>
                        </ul>
                    </div>
                    <div>
                        <h2 className="border border-white border-b-red-800 p-4 cursor-pointer" onClick={toggleSolutionsVisibility}>Solutions <i className={`fa fa-caret-down text-xs transition-transform duration-300 ${isSolutionsVisible ? 'rotate-180' : ''}`} aria-hidden="true"></i></h2>
                        <ul className={`pt-5 transition-all duration-300 ${isSolutionsVisible ? 'block' : 'hidden'}`}>
                            <li className="flex items-center justify-start gap-5 pl-4"><input type="checkbox" id="IP Consulting Services" value="IP Consulting Services" onChange={handleCheckboxChange} /><label htmlFor="IP Consulting Services">IP Consulting Services</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input type="checkbox" id="Strategic Market Insights" value="Strategic Market Insights" onChange={handleCheckboxChange} /><label htmlFor="Strategic Market Insights">Strategic Market Insights</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input type="checkbox" id="Competitive & Consumer Intelligence" value="Competitive & Consumer Intelligence" onChange={handleCheckboxChange} /><label htmlFor="Competitive & Consumer Intelligence">Competitive & Consumer Intelligence</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input type="checkbox" id="Innovation & Technology Strategy" value="Innovation & Technology Strategy" onChange={handleCheckboxChange} /><label htmlFor="Innovation & Technology Strategy">Innovation & Technology Strategy</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input type="checkbox" id="Growth & Market Expansion" value="Growth & Market Expansion" onChange={handleCheckboxChange} /><label htmlFor="Growth & Market Expansion">Growth & Market Expansion</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input type="checkbox" id="Operational Excellence & Benchmarking" value="Operational Excellence & Benchmarking" onChange={handleCheckboxChange} /><label htmlFor="Operational Excellence & Benchmarking">Operational Excellence & Benchmarking</label></li>
                            <li className="flex items-center justify-start gap-5 pl-4"><input type="checkbox" id="Emerging Opportunities & Ecosystem Mapping" value="Emerging Opportunities & Ecosystem Mapping" onChange={handleCheckboxChange} /><label htmlFor="Emerging Opportunities & Ecosystem Mapping">Emerging Opportunities & Ecosystem Mapping</label></li>
                        </ul>
                    </div>
                </div>
                <div className={`hidden w-full overflow-hidden xs:max-sm:block`}>
                    <div className="flex items-center justify-between border border-white border-b-red-800">
                        <h2 className= "p-4 cursor-pointer text-xs" onClick={toggleIndustriesVisibility}>Industries <i className={`fa fa-caret-down text-xs transition-transform duration-300 ${isIndustriesVisible ? 'rotate-180' : ''}`} aria-hidden="true"></i></h2>
                        <h2 className= "p-4 cursor-pointer text-xs" onClick={toggleSolutionsVisibility}>Solutions <i className={`fa fa-caret-down text-xs transition-transform duration-300 ${isSolutionsVisible ? 'rotate-180' : ''}`} aria-hidden="true"></i></h2>
                     </div>
                    <ul className={`pt-5 text-xxs transition-all duration-300 grid-cols-2 grid-rows-auto gap-2  ${isIndustriesVisible ? 'grid' : 'hidden'}`}>
                        {['Chemicals', 'Technology Media and Telecom', 'Energy and Natural Resources', 'Food and Nutrition', 'Healthcare and Life Sciences', 'Mobility', 'Industrials', 'Home and Personal Care'].map(industry => (
                            <li className={`flex items-center justify-center  ${selectedIndustries.includes(industry) ? 'text-white font-semibold bg-red-800 p-1 text-center rounded-full' : 'bg-gray-200 text-gray-500 font-semibold p-1 text-center rounded-full'}`} key={industry}>
                                <label>
                                    {industry}
                                    <input className={`hidden`} type="checkbox" id={industry} value={industry} onChange={handleCheckboxChange} />
                                </label>
                            </li>
                        ))}
                    </ul>
                    <ul className={`pt-5 text-xxs transition-all duration-300 grid-cols-2 grid-rows-auto gap-2  ${isSolutionsVisible ? 'grid' : 'hidden'}`}>
                        {['IP Consulting Services', 'Strategic Market Insights', 'Competitive & Consumer Intelligence', 'Innovation & Technology Strategy', 'Growth & Market Expansion', 'Operational Excellence & Benchmarking', 'Emerging Opportunities & Ecosystem Mapping'].map(solution => (
                            <li className={`flex items-center justify-center  ${selectedIndustries.includes(solution) ? 'text-white font-semibold bg-red-800 p-1 text-center rounded-full' : 'bg-gray-200 text-gray-500 font-semibold p-1 text-center rounded-full'}`} key={solution}>
                                <label>
                                    {solution}
                                    <input className={`hidden`}  type="checkbox" id={solution} value={solution} onChange={handleCheckboxChange} />
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="grid grid-cols-3 auto-rows-auto gap-10 w-9/12 xs:max-sm:flex xs:max-sm:flex-col xs:max-sm:items-center xs:max-sm:justify-center xs:max-sm:gap-5 xs:max-sm:w-full ">
                    {filter.map((article, index) => (
                        <div key={index} className="flex flex-col gap-2 bg-white rounded shadow h-[350px] p-5 relative xs:max-sm:hidden">
                            <img className="w-full h-36 object-cover" src={`/insights_img/${article.main_image_all}`} alt={article.title} />
                            <p className="absolute text-xxs border border-red-800 rounded-sm text-red-800 bg-white font-semibold top-8 left-8 px-1 py-[2px]">{article.insight_type}</p>
                            <p className="absolute right-8 text-xxs border border-red-800 rounded-sm text-red-800 bg-white font-semibold top-8 px-1 py-[2px]">{format(new Date(ExcelDateToJSDate(article.Submitted_on).toDateString()), 'EEEE, MMM, d, yyyy')}</p>
                            <span className="text-[10px]">{article.Industry1}, {article.Industry2}, {article.Industry3}</span>
                            <p className="text-[14px] py-5">{article.title}</p>
                            <Link to={`/${article.title.replaceAll(' ', '-')}`} className="absolute bottom-3 left-5 flex items-center gap-2 text-xs text-red-800 w-full">Read More <i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                        </div>
                    ))}
                    {filter.map((article, index) => (
                        <div key={index} className="hidden bg-white rounded p-4 shadow relative xs:max-sm:block w-full">
                            <div className="flex items-center justify-center gap-2 relative overflow-hidden h-5/6">
                                <img className="object-cover w-6/12 h-[50px]" src={`/insights_img/${article.main_image_all}`} alt={article.title} />
                                <span className="text-[10px]">{article.Industry1}, {article.Industry2}, {article.Industry3}</span>
                            </div>
                            <div className="relative" >
                                <p className="text-[14px] pt-4 pb-8 ">{article.title}</p>
                                <div className="absolute bottom-0 flex items-center justify-between w-full ">
                                    <div className="flex items-center justify-center gap-2">
                                        <p className="text-xxs border border-red-800 rounded-sm text-red-800 bg-white font-semibold px-1 py-[2px]">{article.insight_type}</p>
                                        <p className="text-xxs border border-red-800 rounded-sm text-red-800 bg-white font-semibold px-1 py-[2px]">{format(new Date(ExcelDateToJSDate(article.Submitted_on).toDateString()), 'EEEE, MMM, d, yyyy')}</p>
                                    </div>
                                    <Link to={`/${article.title.replaceAll(' ', '-')}`} className="flex items-center gap-2 text-[11px] text-red-800">Read More <i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Insights;
